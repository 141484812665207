.guide[data-v-60400352] {
  width: 100vw;
  height: 100vh;
  background-image: url(../../static/img/guidebg.91595794.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.guide-box[data-v-60400352] {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.guide-box .logo[data-v-60400352] {
  position: absolute;
  top: 1.6rem;
}
.guide-box .title[data-v-60400352] {
  font-weight: 400;
  color: var(--themeColor);
  position: absolute;
  top: 62%;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.guide-box .enter[data-v-60400352] {
  font-weight: 400;
  color: #ffffff;
  background: -webkit-gradient(linear, left top, right top, from(#04aaad), to(#1aaac9));
  background: linear-gradient(90deg, #04aaad, #1aaac9);
  position: absolute;
  top: 78%;
  cursor: pointer;
}